export default {
    namespaced: true,

    state: {
        sliders: [],
        featuredProducts: [],
        productsData: [],
        productsdetails: [],
        brands: [],
        promo: [],
        helmatsProduct: [],
        offerProduct: [],
        view: [],
        
    },
    getters: {
        sliders(state) {
            return state.sliders.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        featuredProducts(state) {
            return state.featuredProducts.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        products(state) {
            return state.productsData.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        productsdetails(state) {
            return state.productsdetails;
        },
        view(state) {
            return state.view;
        },
        promo(state) {
            return state.promo;
        },
        brands(state) {
            return state.brands.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        helmatsProduct(state) {
            return state.helmatsProduct;
        },
        offerProduct(state) {
            return state.offerProduct;
        },
    },

    mutations: {
        setSlider(state, sliders) {
            state.sliders = sliders;
        },
        setFeaturedProducts(state, featuredProducts) {
            state.featuredProducts = featuredProducts;
        },
        setCategoryWaysProduct(state, productsData) {
            state.productsData = productsData;
        },
        setDetails(state, productsdetails) {
            state.productsdetails = productsdetails;
        },
        setView(state, view) {
            state.view = view;
        },
        setBrands(state, brands) {
            state.brands = brands;
        },
        setPrmo(state, promo) {
            state.promo = promo;
        },
        setHelmatsProduct(state, helmatsProduct) {
            state.helmatsProduct = helmatsProduct;
        },
        setOfferProduct(state, offerProduct) {
            state.offerProduct = offerProduct;
        },
    },
    actions: {
        async brandProduct(context, brand){
            let isSuccess = false;
            await axios.post(`${this.state.host}/get-brand-product`, brand, {
                    headers: {
                        Authorization: this.getters['user/GET_AUTH_TOKEN'],
                    },
                }).then(res => {
                    context.commit('setCategoryWaysProduct', res.data.brandProduct);
                    return res.data.brandProduct;
                }).catch(error => {
                    // //this.dispatch('notification/error', error);
                });

            return isSuccess;
        },
        
        async helmatefilterProduct(context) {
            let helmatsProduct = await axios.get(`${this.state.host}/get-helmate-product-for-ui`)
                .then(res => {
                    context.commit('setHelmatsProduct', res.data.helmatsProduct);
                    return res.data.helmatsProduct;
                })
                .catch(error => {
                    //this.dispatch('notification/error', error);
                });
        },
        async offerProduct(context) {
            let offerProduct = await axios.get(`${this.state.host}/get-offer-product-ui`)
                .then(res => {
                    context.commit('setOfferProduct', res.data.offerProduct);
                    return res.data.offerProduct;
                })
                .catch(error => {
                    //this.dispatch('notification/error', error);
                });
        },
        async getPromoVideo(context) {
            let promo = await axios.get(`${this.state.host}/get-promo-video`)
                .then(res => {
                    context.commit('setPrmo', res.data.promo);
                    return res.data.promo;
                })
                .catch(error => {
                    //this.dispatch('notification/error', error);
                });
        },
        async getBrand(context) {
            let brands = await axios.get(`${this.state.host}/get-brands`)
                .then(res => {
                    return res.data.brands;
                })
                .catch(error => {
                    //this.dispatch('notification/error', error);
                });

            context.commit('setBrands', brands);
        },
        async getUrlBrand(context, category) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/get-url-brand`, category, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.commit('setCategoryWaysProduct', res.data.products);
                    this.$router.push({ name: 'parentproduct' });
                    return res.data.products;
                })
                .catch(error => {
                    //this.dispatch('notification/error', error);
                });

            return isSuccess;
        },
        async getUrlproduct(context, category) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/get-url-product`, category, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.commit('setDetails', res.data.products);
                    this.$router.push({ name: 'productDetails' });
                    return res.data.products;
                })
                .catch(error => {
                    //this.dispatch('notification/error', error);
                });

            return isSuccess;
        },
        async getUrlCategory(context, category) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/get-url-category`, category)
                .then(res => {
                    context.commit('setCategoryWaysProduct', res.data.products);
                    this.$router.push({ name: 'parentproduct' });
                    return res.data.products;
                })
                .catch(error => {
                    //this.dispatch('notification/error', error);
                });
                
            return isSuccess;
        },
        productDetails(context, details){
            context.commit('setDetails', details);
        },
        quickView(context, view){
            context.commit('setView', view);
        },
        async getSlider(context) {
            let sliders = await axios.get(`${this.state.host}/get-slider`)
                .then(res => {
                    return res.data.sliders;
                })
                .catch(error => {
                    //this.dispatch('notification/error', error);
                });

            context.commit('setSlider', sliders);
        },
        async getFeaturedProducts(context) {
            let featuredProducts = await axios.get(`${this.state.host}/get-featured-product`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    return res.data.featuredProducts;
                })
                .catch(error => {
                    //this.dispatch('notification/error', error);
                });

            context.commit('setFeaturedProducts', featuredProducts);
        },
        async getCategoryWaysProduct(context, product) {
            let productsData = axios.post(`${this.state.host}/get-category-ways-product`, product, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.commit('setCategoryWaysProduct', res.data.productsData);
                    return res.data.productsData;
                    
                })
                .catch(error => {
                    //this.dispatch('notification/error', error);
                });
        },
    }
}
