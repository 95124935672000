import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
const routes = [
  {
    path: "/",
    name: "home",
    component: () => import('../views/home/home.vue'),
  },
  {
    path: "/about-us",
    name: "about",
    component: () => import('../views/other/about.vue'),
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import('../views/other/cart.vue'),
  },
  {
    path: "/check-out",
    name: "checkOut",
    component: () => import('../views/other/checkOut.vue'),
  },
  {
    path: "/request-quote",
    name: "requestQuote",
    component: () => import('../views/other/requestQuote.vue'),
  },
  {
    path: "/request-quote-success",
    name: "requestQuotesuccess",
    component: () => import('../views/other/requestQuotesuccess.vue'),
  },
  {
    path: "/order-sucess",
    name: "afterpayment",
    component: () => import('../views/other/afterpayment.vue'),
  },
  {
    path: "/order-fail",
    name: "orderfail",
    component: () => import('../views/other/orderfail.vue'),
  },
  {
    path: "/contact-us",
    name: "contect",
    component: () => import('../views/other/contect.vue'),
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: () => import('../views/other/privacyPolicy.vue'),
  },
  {
    path: "/shipping-policy",
    name: "shippingPolicy",
    component: () => import('../views/other/shippingPolicy.vue'),
  },
  {
    path: "/warrenty-policy",
    name: "WarrentyPolicy",
    component: () => import('../views/other/WarrentyPolicy.vue'),
  },
  {
    path: "/returns-refund",
    name: "returnsPolicy",
    component: () => import('../views/other/returnsPolicy.vue'),
  },
  {
    path: "/terms-condition",
    name: "termsCondition",
    component: () => import('../views/other/termsCondition.vue'),
  },
  {
    path: "/careers",
    name: "careers",
    component: () => import('../views/other/careers'),
  },
  {
    path: "/news",
    name: "news",
    component: () => import('../views/other/news.vue'),
  },
  {
    path: "/:slug",
    name: "parentproduct",
    component: () => import('../views/products/product.vue'),
    meta: {
      isMain: true,
      public: true,  // Allow access to even if not logged in
      requireAdmin: false,
  },
  },
  {
    path: "/:main/:sub",
    name: "subproduct",
    component: () => import('../views/products/product.vue'),
   
  },
  {
    path: "/:frist/:second/:third",
    name: "childproduct",
    component: () => import('../views/products/product.vue'),
    meta: {
        isMain: true,
        public: true,  // Allow access to even if not logged in
        requireAdmin: false,
    },
  },
  {
    path: "/product/:detail?",
    name: "productDetails",
    component: () => import('../views/products/productdetails.vue'),
      meta: {
        isMain: true,
        public: true,  // Allow access to even if not logged in
        requireAdmin: false,
    },
  },
  {
    path: "/brand/:detail?",
    name: "brandProduct",
    component: () => import('../views/products/product.vue'),
      meta: {
        isMain: true,
        public: true,  // Allow access to even if not logged in
        requireAdmin: false,
    },
  },
  {
    path: "/news/:url",
    name: "newsDerails",
    component: () => import('../views/other/newsDerails'),
      meta: {
        isMain: true,
        public: true,  // Allow access to even if not logged in
        requireAdmin: false,
    },
  },

  {
    path: "/my-account",
    name: "MyAccount",
    component: () => import('../views/accountManage/MyAccount.vue'),
    meta: { requiresAuth : true }
  },
  {
    path: "/login",
    name: "login",
    component: () => import('../views/accountManage/login.vue'),
    meta: { guest : true }
  },
]

const router = createRouter({
  base: '/',
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('customer_token');

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Only check the token if the route requires authentication
    if (!token) {
      next({ name: 'login' });
    } else {
      try {
        const response = await axios.get(`${store.state.host}/verification-customer-token`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (response.status === 200) {
          next();
        } else {
          localStorage.removeItem('customer_token');
          store.dispatch('customerLogin/forceLogout');
          next({ name: 'login' });
        }
      } catch (error) {
        localStorage.removeItem('customer_token');
        store.dispatch('customerLogin/forceLogout');
        next({ name: 'login' });
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    // Redirect to MyAccount if already authenticated
    if (token) {
      next({ name: 'MyAccount' });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router
