import { createStore } from "vuex";
import menu from './menu'
import product from './product'
import home from './home'
import customerLogin from './customerLogin'
import createPersistedState from 'vuex-persistedstate';
import notification from "./notification";
import aditiondata from "./aditiondata";
import cart from "./cart";
import useConfig from '../composable/useConfig';


const { host, hostName } = useConfig()
export default createStore({
    state: () => {
        return {
            host: host,
            hostName: hostName,
        }
    },

    getters: {

    },

    mutations: {

    },

    actions: {

    },

    modules: {
      menu,
      product,
      home,
      notification,
      aditiondata,
      cart,
      customerLogin,
    },
    plugins: [createPersistedState({
        paths: ['customerLogin','cart']
      })],
})