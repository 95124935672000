import router from './../router/index'
export default {
    namespaced:true,

    state:{
        news:[],
        limitNews:[],
        newsDetails:[],
        area:[],
        review:[],
        customerOrder:[],
        url:null,
    },
    getters: {
        news(state) {
            return state.news.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        limitNews(state) {
            return state.limitNews.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        area(state) {
            return state.area.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        review(state) {
            return state.review.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        customerOrder(state) {
            return state.customerOrder.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        newsDetails(state) {
            return state.newsDetails;
        },
        url(state) {
            return state.url;
        },
    },
    mutations:{
        getNews(state, news) {
            state.news = news;
        },
        limitBlog(state, news) {
            state.limitNews = news;
        },
        setDetails(state, newsDetails) {
            state.newsDetails = newsDetails;
        },
        setArea(state, area) {
            state.area = area;
        },
        setReview(state, review) {
            state.review = review;
        },
        setCustomerOrder(state, customerOrder) {
            state.customerOrder = customerOrder;
        },
        seturl(state, url) {
            state.url = url;
        },
    },
    actions:{
        async getCustomerOrder(context) {
            let data = {
                id: this.getters['customerLogin/GET_AUTH_INFO'].id,
            }
            let contact = await axios.post(`${this.state.host}/customer-order`, data, {
                headers: {
                    Authorization: this.getters['customerLogin/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    console.log(res)
                    context.commit('setCustomerOrder', res.data.customerOrder);
                    return res.data.customerOrder;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });
        },
        newsDetails(context, details){
            context.commit('setDetails', details);
        },
        async storContact(context, data) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/store-contact`, data)
                .then(res => {
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });
            return isSuccess;
        },
        async storReview(context, data) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/store-review`, data)
                .then(res => {
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });
            return isSuccess;
        },
        async storeEmail(context, data) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/store-email`, data)
                .then(res => {
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });
            return isSuccess;
        },
        async getReview(context, data) {
            await axios.post(`${this.state.host}/get-review`, data)
                .then(res => {
                    context.commit('setReview', res.data.review);
                    return res.data.review;
                })
                .catch(error => {
                    //this.dispatch('notification/error', error);
                });
        },
        async getArea(context) {
            await axios.get(`${this.state.host}/get-location`)
                .then(res => {
                    context.commit('setArea', res.data.area);
                    return res.data.area;
                })
                .catch(error => {
                    //this.dispatch('notification/error', error);
                });
        },
        async getNewsData(context, data) {
            await axios.post(`${this.state.host}/get-news-data`, data)
                .then(res => {
                    if(data.type == 1){
                        context.commit('limitBlog', res.data.news);
                    }else{
                        context.commit('getNews', res.data.news);
                    }
                }).catch(error => {
                    // this.dispatch('notification/error', error.response.data.message);
                });
        },
        setSsl(context, data){
            context.commit('seturl', data);
        },
        async saveOrder(context, data) {
            await axios.post(`${this.state.host}/store-order`, data)
                .then(res => {
                    if(res.data.type == 'pay'){
                        const url = res.data.data;
                        if (url) {
                            window.location.href = url; 
                        }
                    }else if(res.data.type == 'info'){
                        this.dispatch('notification/success', 'Order Successfully');
                        router.push({ name: 'requestQuotesuccess' });;
                        return true;
                    }
                }).catch(error => {
                    console.log(error)
                    // this.dispatch('notification/error', error.response.data.message);
                });
        },
    }
}