import {toast} from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
    namespaced: true,

    state: {
          
    },
    getters: {
      
    },

    actions: {
        success(context,message){
          return toast.success(message, {
                autoClose: 5000,
                position: toast.POSITION.TOP_RIGHT,
                transition: toast.TRANSITIONS.ZOOM,
                zIndex: 99999999,
              });
        },
        error(context,message){
          return toast.error(message, {
                autoClose: 5000,
                position: toast.POSITION.TOP_RIGHT,
                transition: toast.TRANSITIONS.ZOOM,
                zIndex: 99999999,
              });
        },
    }
}
