export default {
    namespaced:true,
    state:{
        cart:[],
        request:[],
    },
    getters:{
        cart(state) {
            return state.cart.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        request(state) {
            return state.request.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
    },
    mutations:{
        setCart(state, product) {
            const item = state.cart.find(item => item.id === product.id);
            if (item) {
                this.dispatch('notification/error', 'Product already added');
            } else {
              state.cart.push({ ...product});
              this.dispatch('notification/success', 'Add to cart successfully');
            }
        },
        clearCart(state) {
            state.cart = [];
        },
        clearrequest(state) {
            state.request = [];
        },
        setrequest(state, product) {
            const item = state.request.find(item => item.id === product.id);
            if (item) {
                this.dispatch('notification/error', 'Product already added');
            } else {
              state.request.push({ ...product});
              this.dispatch('notification/success', 'Add to cart successfully');
            }
        },
        REMOVE_FROM_CART(state, productId) {
            state.cart = state.cart.filter(item => item.id !== productId);
            this.dispatch('notification/success', 'Delete to cart successfully');
          },
    },
    actions:{
        addToCart(context, product){
            context.commit('setCart', product);
        },
        clearCart({ commit }){
            commit('clearCart');
        },
        clearrequest({ commit }){
            commit('clearrequest');
        },
        request(context, product){
            context.commit('setrequest', product);
        },
        removeToCart(context, product){
            context.commit('REMOVE_FROM_CART', product);
        },
        
    }
}