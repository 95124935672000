export default {
    namespaced: true,

    state: {
        products: [],
        
    },
    getters: {
        products(state) {
            return state.products.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
    },

    mutations: {
        setProduct(state, products) {
            state.products = products;
        },
    },
    actions: {
        async getProduct(context) {
            let products = await axios.get(`${this.state.host}/get-product`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    return res.data.products;
                })
                .catch(error => {
                    //this.dispatch('notification/error', error);
                });

            context.commit('setProduct', products);
        },
    }
}
