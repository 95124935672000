export default {
    namespaced: true,

    state: {
        menus: [],
    },
    getters: {
        menus(state) {
            return state.menus.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
    },
    mutations: {
        setMenus(state, menus) {
            state.menus = menus;
        },
    },
    actions: {
        async getMenus(context) {
            let menus = await axios.get(`${this.state.host}/get-menus`)
                .then(res => {
                    return res.data.menus;
                })
                .catch(error => {
                    //this.dispatch('notification/error', error);
                });

            context.commit('setMenus', menus);
        },
    }
}
